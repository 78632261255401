var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "vx-row mb-2" },
        [
          _c(
            "vx-card",
            {
              attrs: {
                title:
                  _vm.$t(_vm.resources.Booking.i18n) ||
                  _vm.resources.Booking.name
              }
            },
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-user",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.ContactName.i18n) ||
                            _vm.resources.ContactName.name
                        },
                        model: {
                          value: _vm.contactName,
                          callback: function($$v) {
                            _vm.contactName = $$v
                          },
                          expression: "contactName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          icon: "icon-mail",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.Email.i18n) ||
                            _vm.resources.Email.name
                        },
                        model: {
                          value: _vm.email,
                          callback: function($$v) {
                            _vm.email = $$v
                          },
                          expression: "email"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-map-pin",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.CountryOperation.i18n) ||
                            _vm.resources.CountryOperation.name
                        },
                        model: {
                          value: _vm.countryOperation,
                          callback: function($$v) {
                            _vm.countryOperation = $$v
                          },
                          expression: "countryOperation"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.Code.i18n) ||
                            _vm.resources.Code.name
                        },
                        model: {
                          value: _vm.bookingCode,
                          callback: function($$v) {
                            _vm.bookingCode = $$v
                          },
                          expression: "bookingCode"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.NetValue.i18n) ||
                            _vm.resources.NetValue.name
                        },
                        model: {
                          value: _vm.netValue,
                          callback: function($$v) {
                            _vm.netValue = $$v
                          },
                          expression: "netValue"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          readonly: "",
                          icon: "icon-dollar-sign",
                          label:
                            _vm.$t(_vm.resources.TaxesValue.i18n) ||
                            _vm.resources.Source.name
                        },
                        model: {
                          value: _vm.taxValue,
                          callback: function($$v) {
                            _vm.taxValue = $$v
                          },
                          expression: "taxValue"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.FinancialCharge.i18n) ||
                            _vm.resources.FinancialCharge.name
                        },
                        model: {
                          value: _vm.feeValue,
                          callback: function($$v) {
                            _vm.feeValue = $$v
                          },
                          expression: "feeValue"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          "icon-no-border": "",
                          icon: "icon-dollar-sign",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.FeeCustomized.i18n) ||
                            _vm.resources.FeeCustomized.name
                        },
                        model: {
                          value: _vm.feeCustomized,
                          callback: function($$v) {
                            _vm.feeCustomized = $$v
                          },
                          expression: "feeCustomized"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-dollar-sign",
                          "icon-no-border": "",
                          readonly: "",
                          label:
                            _vm.$t(_vm.resources.TotalValue.i18n) ||
                            _vm.resources.TotalValue.name
                        },
                        model: {
                          value: _vm.totalValue,
                          callback: function($$v) {
                            _vm.totalValue = $$v
                          },
                          expression: "totalValue"
                        }
                      })
                    ],
                    1
                  )
                ])
              ])
            ]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-row mb-2" },
        [
          _c(
            "vs-tabs",
            [
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Detail.i18n) ||
                      _vm.resources.Detail.name,
                    "icon-pack": "feather",
                    icon: "icon-file-text"
                  }
                },
                _vm._l(_vm.bookingDetail, function(detail, index) {
                  return _c(
                    "vx-card",
                    { key: index, staticClass: "mb-4" },
                    [
                      _c("h3", { staticClass: "font-weight-bold" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(detail.productName) +
                            " - #" +
                            _vm._s(detail.bookingReference) +
                            "\n          "
                        )
                      ]),
                      _c("p", [
                        _c("small", [
                          _vm._v(
                            "ID: " +
                              _vm._s(detail.bookingDetailId) +
                              "|\n              " +
                              _vm._s(
                                _vm.$t(_vm.resources.CreationDate.i18n) ||
                                  _vm.resources.CreationDate.name
                              ) +
                              ": " +
                              _vm._s(detail.createdDate) +
                              " |\n              " +
                              _vm._s(detail.bookingSourceName)
                          )
                        ])
                      ]),
                      _c(
                        "vs-collapse",
                        { staticClass: "mt-4 mb-4", attrs: { type: "border" } },
                        [
                          _c(
                            "vs-collapse-item",
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "font-weight-bold",
                                  attrs: { slot: "header" },
                                  slot: "header"
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          _vm.resources.DetailPrices.i18n
                                        ) || _vm.resources.DetailPrices.name
                                      ) +
                                      "\n              "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "vx-row" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            "icon-no-border": "",
                                            readonly: "",
                                            icon: "icon-dollar-sign",
                                            label:
                                              _vm.$t(
                                                _vm.resources.CurrencyCode.i18n
                                              ) ||
                                              _vm.resources.CurrencyCode.name
                                          },
                                          model: {
                                            value: detail.currencyCode,
                                            callback: function($$v) {
                                              _vm.$set(
                                                detail,
                                                "currencyCode",
                                                $$v
                                              )
                                            },
                                            expression: "detail.currencyCode"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-dollar-sign",
                                            "icon-no-border": "",
                                            type: "number",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.NetValue.i18n
                                              ) || _vm.resources.NetValue.name
                                          },
                                          model: {
                                            value: detail.value,
                                            callback: function($$v) {
                                              _vm.$set(detail, "value", $$v)
                                            },
                                            expression: "detail.value"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            "icon-no-border": "",
                                            icon: "icon-dollar-sign",
                                            type: "number",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.TaxesValue.i18n
                                              ) || _vm.resources.TaxesValue.name
                                          },
                                          model: {
                                            value: detail.tax,
                                            callback: function($$v) {
                                              _vm.$set(detail, "tax", $$v)
                                            },
                                            expression: "detail.tax"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-dollar-sign",
                                            "icon-no-border": "",
                                            type: "number",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.FinancialCharge
                                                  .i18n
                                              ) ||
                                              _vm.resources.FinancialCharge
                                                .name,
                                            value:
                                              detail.fee +
                                              detail.merchant +
                                              detail.agentCommissionValue
                                          },
                                          model: {
                                            value: detail.fee,
                                            callback: function($$v) {
                                              _vm.$set(detail, "fee", $$v)
                                            },
                                            expression: "detail.fee"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c("div", { staticClass: "vx-row mb-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-dollar-sign",
                                            "icon-no-border": "",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.FeeCustomized.i18n
                                              ) ||
                                              _vm.resources.FeeCustomized.name
                                          },
                                          model: {
                                            value: detail.feeCustomized,
                                            callback: function($$v) {
                                              _vm.$set(
                                                detail,
                                                "feeCustomized",
                                                $$v
                                              )
                                            },
                                            expression: "detail.feeCustomized"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/4 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-dollar-sign",
                                            "icon-no-border": "",
                                            type: "number",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.TotalValue.i18n
                                              ) || _vm.resources.TotalValue.name
                                          },
                                          model: {
                                            value: detail.total,
                                            callback: function($$v) {
                                              _vm.$set(detail, "total", $$v)
                                            },
                                            expression: "detail.total"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "vs-divider",
                                {
                                  staticClass: "mt-4 mb-2",
                                  attrs: { color: "success" }
                                },
                                [
                                  _c("h5", { staticClass: "text-success" }, [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.Commissions.i18n
                                          ) || _vm.resources.Commissions.name
                                        ) +
                                        "\n                "
                                    )
                                  ])
                                ]
                              ),
                              _c("div", { staticClass: "vx-row mb-4" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/5 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-hash",
                                            "icon-no-border": "",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.OrderNumber.i18n
                                              ) ||
                                              _vm.resources.OrderNumber.name
                                          },
                                          model: {
                                            value: detail.infotraxOrder,
                                            callback: function($$v) {
                                              _vm.$set(
                                                detail,
                                                "infotraxOrder",
                                                $$v
                                              )
                                            },
                                            expression: "detail.infotraxOrder"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/5 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            "icon-no-border": "",
                                            icon: "icon-calendar",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.Period.i18n
                                              ) || _vm.resources.Period.name
                                          },
                                          model: {
                                            value: detail.infotraxWeek,
                                            callback: function($$v) {
                                              _vm.$set(
                                                detail,
                                                "infotraxWeek",
                                                $$v
                                              )
                                            },
                                            expression: "detail.infotraxWeek"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/5 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            "icon-no-border": "",
                                            icon: "icon-calendar",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.CreationDate.i18n
                                              ) ||
                                              _vm.resources.CreationDate.name
                                          },
                                          model: {
                                            value: detail.creationDateOrder,
                                            callback: function($$v) {
                                              _vm.$set(
                                                detail,
                                                "creationDateOrder",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "detail.creationDateOrder"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/5 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-dollar-sign",
                                            "icon-no-border": "",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.Value.i18n
                                              ) || _vm.resources.Value.name
                                          },
                                          model: {
                                            value: detail.itacommission,
                                            callback: function($$v) {
                                              _vm.$set(
                                                detail,
                                                "itacommission",
                                                $$v
                                              )
                                            },
                                            expression: "detail.itacommission"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "vx-col md:w-1/5 w-full mb-2"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "vx-col w-full" },
                                      [
                                        _c("vs-input", {
                                          staticClass: "w-full",
                                          attrs: {
                                            "icon-pack": "feather",
                                            icon: "icon-package",
                                            "icon-no-border": "",
                                            readonly: "",
                                            label:
                                              _vm.$t(
                                                _vm.resources.BVPoints.i18n
                                              ) || _vm.resources.BVPoints.name
                                          },
                                          model: {
                                            value: detail.bvPoints,
                                            callback: function($$v) {
                                              _vm.$set(detail, "bvPoints", $$v)
                                            },
                                            expression: "detail.bvPoints"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                )
                              ]),
                              _c("vs-divider", { attrs: { color: "success" } })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "vs-tabs",
                        {
                          staticStyle: {
                            width: "auto",
                            height: "auto",
                            overflow: "auto"
                          }
                        },
                        [
                          _c(
                            "vs-tab",
                            {
                              attrs: {
                                label:
                                  _vm.$t(_vm.resources.Passengers.i18n) ||
                                  _vm.resources.Passengers.name
                              }
                            },
                            [
                              _c(
                                "vs-table",
                                {
                                  attrs: {
                                    data: _vm.filterPassenger(
                                      detail.bookingDetailId
                                    )
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var data = ref.data
                                          return _vm._l(data, function(
                                            tr,
                                            indextr
                                          ) {
                                            return _c(
                                              "vs-tr",
                                              { key: indextr },
                                              [
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr]
                                                          .passengerTypeName
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr]
                                                          .passengerTypeName
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr].firstName
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].firstName
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr].lastName
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].lastName
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr]
                                                          .documentNumber
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr]
                                                          .documentNumber
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  {
                                                    attrs: {
                                                      data:
                                                        data[indextr].birthDate
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        data[indextr].birthDate
                                                      )
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "vs-td",
                                                  [
                                                    _c(
                                                      "vx-tooltip",
                                                      {
                                                        attrs: {
                                                          text:
                                                            _vm.$t(
                                                              _vm.resources.View
                                                                .i18n
                                                            ) ||
                                                            _vm.resources.View
                                                              .name
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "a",
                                                          {
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.showPopUpPassenger(
                                                                  data[indextr],
                                                                  true
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              staticClass:
                                                                "w-5 h-5 mr-1",
                                                              attrs: {
                                                                icon: "EyeIcon"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          })
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "template",
                                    { slot: "thead" },
                                    [
                                      _c("vs-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(_vm.resources.Type.i18n) ||
                                              _vm.resources.Type.name
                                          )
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(_vm.resources.Name.i18n) ||
                                              _vm.resources.Name.name
                                          )
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.LastName.i18n
                                            ) || _vm.resources.LastName.name
                                          )
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.DocumentNumber.i18n
                                            ) ||
                                              _vm.resources.DocumentNumber.name
                                          )
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.BirthDate.i18n
                                            ) || _vm.resources.BirthDate.name
                                          )
                                        )
                                      ]),
                                      _c("vs-th", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.Options.i18n
                                            ) || _vm.resources.Options.name
                                          )
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "vs-tab",
                            {
                              attrs: {
                                label:
                                  _vm.$t(_vm.resources.Itinerary.i18n) ||
                                  _vm.resources.Passengers.name
                              }
                            },
                            [
                              _c("itinerary", {
                                attrs: {
                                  bookingId: _vm.bookingId,
                                  productId: detail.productId,
                                  bookingDetailId: detail.bookingDetailId
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "vs-tab",
                            {
                              attrs: {
                                label:
                                  _vm.$t(_vm.resources.Tracking.i18n) ||
                                  _vm.resources.Tracking.name
                              }
                            },
                            [
                              _c("tracking", {
                                attrs: {
                                  bookingId: _vm.bookingId,
                                  productId: "" + detail.productId,
                                  bookingDetailId: "" + detail.bookingDetailId
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Payment.i18n) ||
                      _vm.resources.Payment.name,
                    "icon-pack": "feather",
                    icon: "icon-credit-card"
                  }
                },
                [
                  _vm.bookingDetail.length > 0
                    ? _c("payment", {
                        attrs: {
                          bookingId: _vm.bookingId,
                          countryOperationCode: _vm.countryOperation,
                          stageId: _vm.stageId,
                          productId: _vm.bookingDetail.find(function(x) {
                            return x.supplierId != 0
                          }).productId,
                          supplierId: _vm.bookingDetail.find(function(x) {
                            return x.supplierId != 0
                          }).supplierId,
                          currencyCode: "" + _vm.bookingDetail[0].currencyCode,
                          contactId: _vm.bookingDetail[0].contactId,
                          bookingList: _vm.detailsList
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Comments.i18n) ||
                      _vm.t(_vm.resources.Comments.name),
                    "icon-pack": "feather",
                    icon: "icon-message-circle"
                  }
                },
                [
                  _c("comments", {
                    attrs: {
                      bookingId: "" + _vm.bookingId,
                      currentStage: _vm.stageId,
                      purchaseType: 1
                    }
                  })
                ],
                1
              ),
              _c(
                "vs-tab",
                {
                  attrs: {
                    label:
                      _vm.$t(_vm.resources.Documents.i18n) ||
                      _vm.t(_vm.resources.Documents.name),
                    "icon-pack": "feather",
                    icon: "icon-file"
                  },
                  on: { click: _vm.getDocuments }
                },
                [
                  _c("div", { staticClass: "vx-row" }, [
                    _c(
                      "div",
                      { staticClass: "vx-col w-full mb-2" },
                      [
                        _c("vx-card", [
                          _c(
                            "ul",
                            { staticClass: "vx-timeline" },
                            _vm._l(_vm.documents, function(item) {
                              return _c(
                                "li",
                                { key: item.id, staticClass: "mb-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "timeline-icon",
                                      class: "bg-" + item.color
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "FileIcon",
                                          svgClasses:
                                            "text-white stroke-current w-5 h-5"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "small",
                                    { staticClass: "activity-e-time" },
                                    [_vm._v(_vm._s(item.time))]
                                  ),
                                  _c("p", { staticClass: "font-semibold" }, [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.$t(
                                            _vm.resources.CreatedBy.i18n
                                          ) || _vm.resources.CreatedBy.name
                                        ) +
                                        ": " +
                                        _vm._s(item.user) +
                                        "\n                  "
                                    )
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "timeline-info mt-1" },
                                    [
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold mb-2" },
                                        [
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.$t(
                                                  _vm.resources.Subject.i18n
                                                ) || _vm.resources.Subject.name
                                              ) +
                                              ": " +
                                              _vm._s(item.title) +
                                              "\n                    "
                                          )
                                        ]
                                      ),
                                      _c("a", { attrs: { href: item.url } }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              _vm.resources.OpenDocument.i18n
                                            ) || _vm.resources.OpenDocument.name
                                          )
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ])
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "vs-row",
        { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-2",
              attrs: { color: "rgb(174,174,174)", type: "filled" },
              on: {
                click: function($event) {
                  return _vm.$router.push("/bookings/index")
                }
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t(_vm.resources.Return.i18n) || _vm.resources.Return.name
                )
              )
            ]
          )
        ],
        1
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Create.i18n) || _vm.resources.Create.name,
            active: _vm.popupAddDetail
          },
          on: {
            "update:active": function($event) {
              _vm.popupAddDetail = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-base" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ReferenceCode.i18n) ||
                        _vm.resources.ReferenceCode.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.bookingDetail,
                    reduce: function(data) {
                      return data.bookingDetailId
                    },
                    label: "bookingReference"
                  },
                  model: {
                    value: _vm.createDetail.bookingDetailId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "bookingDetailId", $$v)
                    },
                    expression: "createDetail.bookingDetailId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 mb-2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Supplier.i18n) ||
                        _vm.resources.Supplier.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.supplierOption,
                    placeholder:
                      _vm.$t(_vm.resources.Select.i18n) ||
                      _vm.resources.Select.name,
                    reduce: function(item) {
                      return item.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.createDetail.supplierId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "supplierId", $$v)
                    },
                    expression: "createDetail.supplierId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Currency.i18n) ||
                        _vm.resources.Currency.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: false,
                    options: _vm.currencyList,
                    reduce: function(data) {
                      return data.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.createDetail.currencyId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "currencyId", $$v)
                    },
                    expression: "createDetail.currencyId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.ProductType.i18n) ||
                        _vm.resources.ProductType.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    clearable: true,
                    options: _vm.productType,
                    reduce: function(product) {
                      return product.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.createDetail.productId,
                    callback: function($$v) {
                      _vm.$set(_vm.createDetail, "productId", $$v)
                    },
                    expression: "createDetail.productId"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.BookingCode.i18n) ||
                        _vm.resources.BookingCode.name
                    },
                    on: {
                      blur: function($event) {
                        _vm.createDetail.bookingReference = _vm.createDetail.bookingReference.replace(
                          /\s+/g,
                          ""
                        )
                      }
                    },
                    model: {
                      value: _vm.createDetail.bookingReference,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "bookingReference", $$v)
                      },
                      expression: "createDetail.bookingReference"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.NetValue.i18n) ||
                        _vm.resources.NetValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.value,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "value", $$v)
                      },
                      expression: "createDetail.value"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.TaxesValue.i18n) ||
                        _vm.resources.TaxesValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.tax,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "tax", $$v)
                      },
                      expression: "createDetail.tax"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.FeeValue.i18n) ||
                        _vm.resources.FeeValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.fee,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "fee", $$v)
                      },
                      expression: "createDetail.fee"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.AgentCommissionValue.i18n) ||
                        _vm.resources.AgentCommissionValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.agentCommission,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "agentCommission", $$v)
                      },
                      expression: "createDetail.agentCommission"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      type: "number",
                      icon: "icon-dollar-sign",
                      label:
                        _vm.$t(_vm.resources.MerchantValue.i18n) ||
                        _vm.resources.MerchantValue.name
                    },
                    on: {
                      change: function($event) {
                        return _vm.sumValues(0, true)
                      }
                    },
                    model: {
                      value: _vm.createDetail.merchant,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "merchant", $$v)
                      },
                      expression: "createDetail.merchant"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-dollar-sign",
                      "icon-no-border": "",
                      type: "number",
                      readonly: "",
                      label:
                        _vm.$t(_vm.resources.BookingValue.i18n) ||
                        _vm.resources.BookingValue.name
                    },
                    model: {
                      value: _vm.createDetail.total,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "total", $$v)
                      },
                      expression: "createDetail.total"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-dollar-sign",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.FeeCustomized.i18n) ||
                        _vm.resources.FeeCustomized.name
                    },
                    model: {
                      value: _vm.createDetail.feeCustomized,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "feeCustomized", $$v)
                      },
                      expression: "createDetail.feeCustomized"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      "icon-pack": "feather",
                      icon: "icon-user",
                      label:
                        _vm.$t(_vm.resources.CreatedBy.i18n) ||
                        _vm.resources.CreatedBy.name,
                      readonly: ""
                    },
                    model: {
                      value: _vm.createDetail.createdBy,
                      callback: function($$v) {
                        _vm.$set(_vm.createDetail, "createdBy", $$v)
                      },
                      expression: "createDetail.createdBy"
                    }
                  })
                ],
                1
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "ul",
                {
                  staticClass: "centerx",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.createDetail.copyItinerary,
                            callback: function($$v) {
                              _vm.$set(_vm.createDetail, "copyItinerary", $$v)
                            },
                            expression: "createDetail.copyItinerary"
                          }
                        },
                        [_vm._v("Copiar itinerarios")]
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "ul",
                {
                  staticClass: "centerx",
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c(
                    "li",
                    [
                      _c(
                        "vs-checkbox",
                        {
                          model: {
                            value: _vm.createDetail.copyPassenger,
                            callback: function($$v) {
                              _vm.$set(_vm.createDetail, "copyPassenger", $$v)
                            },
                            expression: "createDetail.copyPassenger"
                          }
                        },
                        [_vm._v("Copiar Pasajeros")]
                      )
                    ],
                    1
                  )
                ]
              )
            ])
          ]),
          _c(
            "div",
            { staticClass: "vx-col mb-2 mt-5" },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.saveDetail()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(_vm.resources.Save.i18n) ||
                          _vm.resources.Save.name
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.Passengers.i18n) ||
              _vm.resources.Passengers.name,
            active: _vm.popupActivePassenger
          },
          on: {
            "update:active": function($event) {
              _vm.popupActivePassenger = $event
            }
          }
        },
        [
          _c("div", { staticClass: "vx-row mb-4" }, [
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        _vm.$t(_vm.resources.PassengerType.i18n) ||
                        _vm.resources.PassengerType.name
                    },
                    model: {
                      value: _vm.passenger.passengerTypeId,
                      callback: function($$v) {
                        _vm.$set(_vm.passenger, "passengerTypeId", $$v)
                      },
                      expression: "passenger.passengerTypeId"
                    }
                  },
                  _vm._l(_vm.passengerType, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: {
                        readonly: "",
                        value: item.value,
                        text: item.text
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        _vm.$t(_vm.resources.DocumentType.i18n) ||
                        _vm.resources.DocumentType.name
                    },
                    model: {
                      value: _vm.passenger.documentType,
                      callback: function($$v) {
                        _vm.$set(_vm.passenger, "documentType", $$v)
                      },
                      expression: "passenger.documentType"
                    }
                  },
                  _vm._l(_vm.identifiacionTypeList, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: { readonly: "", value: item.id, text: item.name }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      readonly: "",
                      "icon-pack": "feather",
                      icon: "icon-hash",
                      "icon-no-border": "",
                      label:
                        _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                        _vm.resources.DocumentNumber.name
                    },
                    model: {
                      value: _vm.passenger.documentNumber,
                      callback: function($$v) {
                        _vm.$set(_vm.passenger, "documentNumber", $$v)
                      },
                      expression: "passenger.documentNumber"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-hash",
                    label:
                      _vm.$t(_vm.resources.BirthDate.i18n) ||
                      _vm.resources.BirthDate.name
                  },
                  model: {
                    value: _vm.passenger.birthDate,
                    callback: function($$v) {
                      _vm.$set(_vm.passenger, "birthDate", $$v)
                    },
                    expression: "passenger.birthDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-user",
                    label:
                      _vm.$t(_vm.resources.Name.i18n) || _vm.resources.Name.name
                  },
                  model: {
                    value: _vm.passenger.firstName,
                    callback: function($$v) {
                      _vm.$set(_vm.passenger, "firstName", $$v)
                    },
                    expression: "passenger.firstName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-user",
                    label:
                      _vm.$t(_vm.resources.LastName.i18n) ||
                      _vm.resources.LastName.name
                  },
                  model: {
                    value: _vm.passenger.lastName,
                    callback: function($$v) {
                      _vm.$set(_vm.passenger, "lastName", $$v)
                    },
                    expression: "passenger.lastName"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c(
                  "vs-select",
                  {
                    staticClass: "w-full",
                    attrs: {
                      label:
                        _vm.$t(_vm.resources.Gender.i18n) ||
                        _vm.resources.Gender.name
                    },
                    model: {
                      value: _vm.passenger.genderId,
                      callback: function($$v) {
                        _vm.$set(_vm.passenger, "genderId", $$v)
                      },
                      expression: "passenger.genderId"
                    }
                  },
                  _vm._l(_vm.genderType, function(item, index) {
                    return _c("vs-select-item", {
                      key: index,
                      attrs: {
                        readonly: "",
                        value: item.value,
                        text: item.text
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Country.i18n) ||
                        _vm.resources.Country.name
                    )
                  )
                ]),
                _c("v-select", {
                  attrs: {
                    readonly: "",
                    clearable: false,
                    options: _vm.countryList,
                    reduce: function(country) {
                      return country.id
                    },
                    label: "name"
                  },
                  model: {
                    value: _vm.passenger.nationalityId,
                    callback: function($$v) {
                      _vm.$set(_vm.passenger, "nationalityId", $$v)
                    },
                    expression: "passenger.nationalityId"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-hash",
                    label:
                      _vm.$t(_vm.resources.ExpirationDatePassport.i18n) ||
                      _vm.resources.ExpirationDatePassport.name
                  },
                  model: {
                    value: _vm.passenger.passportExpiration,
                    callback: function($$v) {
                      _vm.$set(_vm.passenger, "passportExpiration", $$v)
                    },
                    expression: "passenger.passportExpiration"
                  }
                })
              ],
              1
            ),
            _c("div", { staticClass: "vx-col md:w-1/2 w-full" }, [
              _c(
                "div",
                { staticClass: "vx-col w-full" },
                [
                  _c("vs-input", {
                    staticClass: "w-full",
                    attrs: {
                      readonly: "",
                      "icon-pack": "feather",
                      "icon-no-border": "",
                      icon: "icon-phone",
                      type: "number",
                      label:
                        _vm.$t(_vm.resources.Phone.i18n) ||
                        _vm.resources.Phone.name
                    },
                    model: {
                      value: _vm.passenger.telephone,
                      callback: function($$v) {
                        _vm.$set(_vm.passenger, "telephone", $$v)
                      },
                      expression: "passenger.telephone"
                    }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "vx-col md:w-1/2 w-full" },
              [
                _c("vs-input", {
                  staticClass: "w-full",
                  attrs: {
                    readonly: "",
                    "icon-pack": "feather",
                    "icon-no-border": "",
                    icon: "icon-hash",
                    type: "number",
                    label:
                      _vm.$t(_vm.resources.TicketNumber.i18n) ||
                      _vm.resources.TicketNumber.name
                  },
                  model: {
                    value: _vm.passenger.ticketNumber,
                    callback: function($$v) {
                      _vm.$set(_vm.passenger, "ticketNumber", $$v)
                    },
                    expression: "passenger.ticketNumber"
                  }
                })
              ],
              1
            )
          ]),
          _c("vs-divider", { staticClass: "mb-2" }, [
            _c("h4", { staticClass: "font-semibold mb-base" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(_vm.resources.Specifications.i18n) ||
                      _vm.resources.Specifications.name
                  ) +
                  "\n      "
              )
            ])
          ]),
          _c("div", { staticClass: "vx-row" }, [
            _c(
              "div",
              { staticClass: "vx-col w-full" },
              [
                _c(
                  "vs-table",
                  {
                    attrs: { data: _vm.passenger.passengerSpecification },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var data = ref.data
                          return _vm._l(data, function(tr, indextr) {
                            return _c(
                              "vs-tr",
                              { key: indextr },
                              [
                                _c(
                                  "vs-td",
                                  {
                                    attrs: {
                                      data: data[indextr].specificationValue
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(data[indextr].specificationValue)
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          })
                        }
                      }
                    ])
                  },
                  [
                    _c(
                      "template",
                      { slot: "thead" },
                      [
                        _c("vs-th", [
                          _vm._v(
                            _vm._s(
                              _vm.$t(_vm.resources.SpecificationName.i18n) ||
                                _vm.resources.SpecificationName.name
                            )
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  2
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }