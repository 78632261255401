var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        {
          staticClass: "mb-4",
          attrs: {
            title: _vm.$t(_vm.resources.List.i18n) || _vm.resources.List.name
          }
        },
        [
          _vm.countryOperationCode != "MEX" &&
          _vm.countryOperationCode != "DOM" &&
          (_vm.stageId == _vm.database.stage.Commercial ||
            _vm.stageId == _vm.database.stage.Risk ||
            _vm.stageId == _vm.database.stage.Issue ||
            _vm.stageId == _vm.database.stage.WithoutBooking ||
            _vm.stageId == _vm.database.stage.Payment ||
            _vm.stageId == _vm.database.stage.Antifraud)
            ? _c(
                "vs-row",
                {
                  staticClass: "mb-base mt-2",
                  attrs: { "vs-type": "flex", "vs-justify": "center" }
                },
                [
                  _c(
                    "vs-col",
                    {
                      attrs: {
                        "vs-type": "flex",
                        "vs-justify": "center",
                        "vs-align": "center",
                        "vs-w": "3"
                      }
                    },
                    [
                      _c("vs-button", {
                        attrs: {
                          radius: "",
                          color: "danger",
                          type: "gradient",
                          "icon-pack": "feather",
                          icon: "icon-link",
                          size: "large"
                        },
                        on: {
                          click: function($event) {
                            _vm.popupGenerate = true
                          }
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "vs-table",
            {
              staticClass: "mb-base",
              attrs: { data: _vm.payments },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var data = ref.data
                    return _vm._l(data, function(tr, indextr) {
                      return _c(
                        "vs-tr",
                        { key: indextr },
                        [
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].currencyCode } },
                            [_vm._v(_vm._s(data[indextr].currencyCode))]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].merchantName } },
                            [_vm._v(_vm._s(data[indextr].merchantName))]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].value } },
                            [_vm._v(_vm._s(data[indextr].value))]
                          ),
                          _c(
                            "vs-td",
                            {
                              attrs: { data: data[indextr].transactionNumber }
                            },
                            [_vm._v(_vm._s(data[indextr].transactionNumber))]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].cardType } },
                            [_vm._v(_vm._s(data[indextr].cardType))]
                          ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].cardNumber } },
                            [_vm._v(_vm._s(data[indextr].cardNumber))]
                          ),
                          data[indextr].isPayed
                            ? _c(
                                "vs-td",
                                { attrs: { data: data[indextr].isPayed } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.Yes.i18n) ||
                                        _vm.resources.Yes.name
                                    )
                                  )
                                ]
                              )
                            : _c(
                                "vs-td",
                                { attrs: { data: data[indextr].isPayed } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.resources.No.i18n) ||
                                        _vm.resources.No.name
                                    )
                                  )
                                ]
                              ),
                          _c(
                            "vs-td",
                            { attrs: { data: data[indextr].createdDate } },
                            [
                              _vm._v(
                                _vm._s(data[indextr].createdDate) +
                                  "\n            " +
                                  _vm._s(data[indextr].createdTime)
                              )
                            ]
                          )
                        ],
                        1
                      )
                    })
                  }
                }
              ])
            },
            [
              _c(
                "template",
                { slot: "thead" },
                [
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CurrencyCode.i18n) ||
                          _vm.resources.CurrencyCode.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Merchant.i18n) ||
                          _vm.resources.Merchant.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Value.i18n) ||
                          _vm.resources.Value.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.TransactionNumber.i18n) ||
                          _vm.resources.TransactionNumber.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Type.i18n) ||
                          _vm.resources.Type.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.CreditCardNumber.i18n) ||
                          _vm.resources.CreditCardNumber.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Paid.i18n) ||
                          _vm.resources.Paid.name
                      )
                    )
                  ]),
                  _c("vs-th", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(_vm.resources.Date.i18n) ||
                          _vm.resources.Date.name
                      )
                    )
                  ])
                ],
                1
              )
            ],
            2
          )
        ],
        1
      ),
      _c(
        "vx-card",
        {
          attrs: {
            title:
              _vm.$t(_vm.resources.BillingData.i18n) ||
              _vm.resources.BillingData.name
          }
        },
        [
          _c(
            "div",
            [
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-user",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Names.i18n) ||
                            _vm.resources.Names.name
                        },
                        model: {
                          value: _vm.billingData.name,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "name", $$v)
                          },
                          expression: "billingData.name"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-user",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.LastName.i18n) ||
                            _vm.resources.LastName.name
                        },
                        model: {
                          value: _vm.billingData.lastName,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "lastName", $$v)
                          },
                          expression: "billingData.lastName"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-mail",
                          "icon-no-border": "",
                          type: "email",
                          label:
                            _vm.$t(_vm.resources.Email.i18n) ||
                            _vm.resources.Email.name
                        },
                        model: {
                          value: _vm.billingData.email,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "email", $$v)
                          },
                          expression: "billingData.email"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-phone",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Phone.i18n) ||
                            _vm.resources.Phone.name
                        },
                        model: {
                          value: _vm.billingData.phone,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "phone", $$v)
                          },
                          expression: "billingData.phone"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Country.i18n) ||
                            _vm.resources.Country.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: _vm.countryList,
                        reduce: function(country) {
                          return country.id
                        },
                        label: "name",
                        hint: "" + _vm.billingData.countryId
                      },
                      on: {
                        input: function($event) {
                          return _vm.getStateByCountry(
                            "" + _vm.billingData.countryId
                          )
                        }
                      },
                      model: {
                        value: _vm.billingData.countryId,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "countryId", $$v)
                        },
                        expression: "billingData.countryId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-6" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.State.i18n) ||
                            _vm.resources.State.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: _vm.stateCountry,
                        reduce: function(state) {
                          return state.id
                        },
                        label: "name",
                        hint: "" + _vm.billingData.stateId
                      },
                      on: {
                        input: function($event) {
                          return _vm.getCityByState(
                            "" + _vm.billingData.stateId
                          )
                        }
                      },
                      model: {
                        value: _vm.billingData.stateId,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "stateId", $$v)
                        },
                        expression: "billingData.stateId"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-6" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.Destination.i18n) ||
                            _vm.resources.Destination.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: _vm.destinationList,
                        reduce: function(city) {
                          return city.id
                        },
                        label: "name"
                      },
                      model: {
                        value: _vm.billingData.cityId,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "cityId", $$v)
                        },
                        expression: "billingData.cityId"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-map-pin",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.Address.i18n) ||
                            _vm.resources.Address.name
                        },
                        model: {
                          value: _vm.billingData.address,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "address", $$v)
                          },
                          expression: "billingData.address"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _c("div", { staticClass: "vx-row" }, [
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.ZipCode.i18n) ||
                            _vm.resources.ZipCode.name
                        },
                        model: {
                          value: _vm.billingData.zipCode,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "zipCode", $$v)
                          },
                          expression: "billingData.zipCode"
                        }
                      })
                    ],
                    1
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "vx-col md:w-1/4 w-full mb-6" },
                  [
                    _c("label", { staticClass: "vs-input--label" }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(_vm.resources.DocumentType.i18n) ||
                            _vm.resources.DocumentType.name
                        )
                      )
                    ]),
                    _c("v-select", {
                      attrs: {
                        clearable: false,
                        options: _vm.identifiacionTypeList,
                        reduce: function(item) {
                          return item.id
                        },
                        label: "name"
                      },
                      model: {
                        value: _vm.billingData.documentTypeId,
                        callback: function($$v) {
                          _vm.$set(_vm.billingData, "documentTypeId", $$v)
                        },
                        expression: "billingData.documentTypeId"
                      }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "vx-col md:w-1/4 w-full mb-2" }, [
                  _c(
                    "div",
                    { staticClass: "vx-col w-full" },
                    [
                      _c("vs-input", {
                        staticClass: "w-full",
                        attrs: {
                          "icon-pack": "feather",
                          icon: "icon-hash",
                          "icon-no-border": "",
                          label:
                            _vm.$t(_vm.resources.DocumentNumber.i18n) ||
                            _vm.resources.DocumentNumber.name
                        },
                        model: {
                          value: _vm.billingData.documentNumber,
                          callback: function($$v) {
                            _vm.$set(_vm.billingData, "documentNumber", $$v)
                          },
                          expression: "billingData.documentNumber"
                        }
                      })
                    ],
                    1
                  )
                ])
              ]),
              _vm.stageId == _vm.database.stage.Commercial ||
              _vm.stageId == _vm.database.stage.Risk ||
              _vm.stageId == _vm.database.stage.Issue ||
              _vm.stageId == _vm.database.stage.WithoutBooking ||
              _vm.stageId == _vm.database.stage.Payment ||
              _vm.stageId == _vm.database.stage.Antifraud
                ? _c(
                    "vs-row",
                    { attrs: { "vs-type": "flex", "vs-justify": "flex-end" } },
                    [
                      _vm.isUpdateBillingData
                        ? _c(
                            "vs-button",
                            {
                              staticClass: "mr-0 mb-2",
                              on: {
                                click: function($event) {
                                  return _vm.createBillingData(_vm.billingData)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.Save.i18n) ||
                                    _vm.resources.Save.name
                                )
                              )
                            ]
                          )
                        : _c(
                            "vs-button",
                            {
                              staticClass: "mr-0 mb-2",
                              on: {
                                click: function($event) {
                                  return _vm.updateBillingData(_vm.billingData)
                                }
                              }
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t(_vm.resources.Save.i18n) ||
                                    _vm.resources.Save.name
                                )
                              )
                            ]
                          )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _c(
        "vs-popup",
        {
          attrs: {
            classContent: "popup overFlow",
            title:
              _vm.$t(_vm.resources.Generate.i18n) ||
              _vm.resources.Generate.name,
            active: _vm.popupGenerate
          },
          on: {
            "update:active": function($event) {
              _vm.popupGenerate = $event
            }
          }
        },
        [
          _c("vs-input", {
            staticClass: "w-full",
            attrs: {
              type: "email",
              "icon-pack": "feather",
              icon: "icon-mail",
              placeholder: "",
              label:
                _vm.$t(_vm.resources.Email.i18n) || _vm.resources.Email.name,
              maxlength: 100
            },
            model: {
              value: _vm.creditCardEmail,
              callback: function($$v) {
                _vm.creditCardEmail = $$v
              },
              expression: "creditCardEmail"
            }
          }),
          _c(
            "div",
            { staticClass: "vx-col w-full" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.ReferenceCode.i18n) ||
                      _vm.resources.ReferenceCode.name
                  )
                )
              ]),
              _c("v-select", {
                attrs: {
                  clearable: false,
                  options: _vm.bookingList,
                  reduce: function(data) {
                    return data.id
                  },
                  label: "value"
                },
                model: {
                  value: _vm.identifier,
                  callback: function($$v) {
                    _vm.identifier = $$v
                  },
                  expression: "identifier"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "vx-col mb-2 mt-5",
              staticStyle: { "text-align": "right" }
            },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary", type: "filled" },
                  on: {
                    click: function($event) {
                      return _vm.sendMail()
                    }
                  }
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t(_vm.resources.Send.i18n) ||
                          _vm.resources.Send.name
                      ) +
                      "\n      "
                  )
                ]
              )
            ],
            1
          ),
          _vm.paymentLink !== null && _vm.paymentLink !== ""
            ? _c("div", { staticClass: "vx-row" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-3/4 mb-2 mt-base" },
                  [
                    _c("vs-input", {
                      staticClass: "w-full",
                      attrs: {
                        "icon-pack": "feather",
                        icon: "icon-link",
                        "icon-no-border": "",
                        readonly: ""
                      },
                      model: {
                        value: _vm.paymentLink,
                        callback: function($$v) {
                          _vm.paymentLink = $$v
                        },
                        expression: "paymentLink"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "vx-col w-full md:w-1/6 mb-4 mt-base" },
                  [
                    _c(
                      "vs-button",
                      {
                        directives: [
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:copy",
                            value: _vm.paymentLink,
                            expression: "paymentLink",
                            arg: "copy"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:success",
                            value: _vm.onCopy,
                            expression: "onCopy",
                            arg: "success"
                          },
                          {
                            name: "clipboard",
                            rawName: "v-clipboard:error",
                            value: _vm.onError,
                            expression: "onError",
                            arg: "error"
                          }
                        ]
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.resources.Copy.i18n) ||
                              _vm.resources.Copy.name
                          )
                        )
                      ]
                    )
                  ],
                  1
                )
              ])
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }