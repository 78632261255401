<!-- =========================================================================================
    File Name: bookingDetail.vue
    Description: bookingDetail Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-row mb-2">
      <vx-card :title="$t(resources.Booking.i18n) || resources.Booking.name">
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                readonly
                :label="
                  $t(resources.ContactName.i18n) || resources.ContactName.name
                "
                v-model="contactName"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                icon="icon-mail"
                readonly
                :label="$t(resources.Email.i18n) || resources.Email.name"
                v-model="email"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map-pin"
                icon-no-border
                readonly
                :label="
                  $t(resources.CountryOperation.i18n) ||
                    resources.CountryOperation.name
                "
                v-model="countryOperation"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                readonly
                :label="$t(resources.Code.i18n) || resources.Code.name"
                v-model="bookingCode"
              />
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="$t(resources.NetValue.i18n) || resources.NetValue.name"
                v-model="netValue"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                readonly
                icon="icon-dollar-sign"
                :label="$t(resources.TaxesValue.i18n) || resources.Source.name"
                v-model="taxValue"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="
                  $t(resources.FinancialCharge.i18n) ||
                    resources.FinancialCharge.name
                "
                v-model="feeValue"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon-no-border
                icon="icon-dollar-sign"
                readonly
                :label="
                  $t(resources.FeeCustomized.i18n) ||
                    resources.FeeCustomized.name
                "
                v-model="feeCustomized"
              />
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-dollar-sign"
                icon-no-border
                readonly
                :label="
                  $t(resources.TotalValue.i18n) || resources.TotalValue.name
                "
                v-model="totalValue"
              />
            </div>
          </div>
        </div>
      </vx-card>
    </div>

    <div class="vx-row mb-2">
      <vs-tabs>
        <vs-tab
          :label="$t(resources.Detail.i18n) || resources.Detail.name"
          icon-pack="feather"
          icon="icon-file-text"
        >
          <!-- <vs-row
            vs-type="flex"
            vs-justify="center"
            class="mb-base mt-2"
            v-if="allowManage"
          >
            <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="3"
            >
              <vs-button
                color="primary"
                type="filled"
                icon="add"
                @click="popupAddDetail = true"
                >{{ $t(resources.Add.i18n) || resources.Add.name }}</vs-button
              >
            </vs-col>
          </vs-row> -->

          <vx-card
            class="mb-4"
            v-for="(detail, index) in bookingDetail"
            :key="index"
          >
            <h3 class="font-weight-bold">
              {{ detail.productName }} - #{{ detail.bookingReference }}
            </h3>
            <p>
              <small
                >ID: {{ detail.bookingDetailId }}|
                {{
                  $t(resources.CreationDate.i18n) ||
                    resources.CreationDate.name
                }}: {{ detail.createdDate }} |
                {{ detail.bookingSourceName }}</small
              >
            </p>
            <vs-collapse type="border" class="mt-4 mb-4">
              <vs-collapse-item>
                <div slot="header" class="font-weight-bold">
                  {{
                    $t(resources.DetailPrices.i18n) ||
                      resources.DetailPrices.name
                  }}
                </div>

                <div class="vx-row">
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        readonly
                        icon="icon-dollar-sign"
                        :label="
                          $t(resources.CurrencyCode.i18n) ||
                            resources.CurrencyCode.name
                        "
                        v-model="detail.currencyCode"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        readonly
                        :label="
                          $t(resources.NetValue.i18n) || resources.NetValue.name
                        "
                        v-model="detail.value"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-dollar-sign"
                        type="number"
                        readonly
                        :label="
                          $t(resources.TaxesValue.i18n) ||
                            resources.TaxesValue.name
                        "
                        v-model="detail.tax"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        readonly
                        :label="
                          $t(resources.FinancialCharge.i18n) ||
                            resources.FinancialCharge.name
                        "
                        v-model="detail.fee"
                        :value="
                          detail.fee +
                            detail.merchant +
                            detail.agentCommissionValue
                        "
                      />
                    </div>
                  </div>
                </div>
                <div class="vx-row mb-2">
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        readonly
                        :label="
                          $t(resources.FeeCustomized.i18n) ||
                            resources.FeeCustomized.name
                        "
                        v-model="detail.feeCustomized"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/4 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        type="number"
                        readonly
                        :label="
                          $t(resources.TotalValue.i18n) ||
                            resources.TotalValue.name
                        "
                        v-model="detail.total"
                      />
                    </div>
                  </div>
                </div>
                <vs-divider color="success" class="mt-4 mb-2">
                  <h5 class="text-success">
                    {{
                      $t(resources.Commissions.i18n) ||
                        resources.Commissions.name
                    }}
                  </h5>
                </vs-divider>

                <div class="vx-row mb-4">
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-hash"
                        icon-no-border
                        readonly
                        :label="
                          $t(resources.OrderNumber.i18n) ||
                            resources.OrderNumber.name
                        "
                        v-model="detail.infotraxOrder"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-calendar"
                        readonly
                        :label="
                          $t(resources.Period.i18n) || resources.Period.name
                        "
                        v-model="detail.infotraxWeek"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon-no-border
                        icon="icon-calendar"
                        readonly
                        :label="
                          $t(resources.CreationDate.i18n) ||
                            resources.CreationDate.name
                        "
                        v-model="detail.creationDateOrder"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-dollar-sign"
                        icon-no-border
                        readonly
                        :label="
                          $t(resources.Value.i18n) || resources.Value.name
                        "
                        v-model="detail.itacommission"
                      />
                    </div>
                  </div>
                  <div class="vx-col md:w-1/5 w-full mb-2">
                    <div class="vx-col w-full">
                      <vs-input
                        class="w-full"
                        icon-pack="feather"
                        icon="icon-package"
                        icon-no-border
                        readonly
                        :label="
                          $t(resources.BVPoints.i18n) || resources.BVPoints.name
                        "
                        v-model="detail.bvPoints"
                      />
                    </div>
                  </div>
                </div>
                <vs-divider color="success"></vs-divider>
              </vs-collapse-item>
            </vs-collapse>
            <vs-tabs style="width: auto; height: auto; overflow: auto">
              <vs-tab
                :label="
                  $t(resources.Passengers.i18n) || resources.Passengers.name
                "
              >
                <vs-table :data="filterPassenger(detail.bookingDetailId)">
                  <template slot="thead">
                    <vs-th>{{
                      $t(resources.Type.i18n) || resources.Type.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Name.i18n) || resources.Name.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.LastName.i18n) || resources.LastName.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.DocumentNumber.i18n) ||
                        resources.DocumentNumber.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.BirthDate.i18n) || resources.BirthDate.name
                    }}</vs-th>
                    <vs-th>{{
                      $t(resources.Options.i18n) || resources.Options.name
                    }}</vs-th>
                  </template>

                  <template slot-scope="{ data }">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                      <vs-td :data="data[indextr].passengerTypeName">{{
                        data[indextr].passengerTypeName
                      }}</vs-td>
                      <vs-td :data="data[indextr].firstName">{{
                        data[indextr].firstName
                      }}</vs-td>
                      <vs-td :data="data[indextr].lastName">{{
                        data[indextr].lastName
                      }}</vs-td>
                      <vs-td :data="data[indextr].documentNumber">{{
                        data[indextr].documentNumber
                      }}</vs-td>
                      <vs-td :data="data[indextr].birthDate">{{
                        data[indextr].birthDate
                      }}</vs-td>
                      <vs-td>
                        <vx-tooltip
                          :text="$t(resources.View.i18n) || resources.View.name"
                        >
                          <a @click="showPopUpPassenger(data[indextr], true)">
                            <feather-icon
                              icon="EyeIcon"
                              class="w-5 h-5 mr-1"
                            ></feather-icon>
                          </a>
                        </vx-tooltip>
                      </vs-td>
                    </vs-tr>
                  </template>
                </vs-table>
              </vs-tab>
              <vs-tab
                :label="
                  $t(resources.Itinerary.i18n) || resources.Passengers.name
                "
              >
                <itinerary
                  :bookingId="bookingId"
                  :productId="detail.productId"
                  :bookingDetailId="detail.bookingDetailId"
                ></itinerary>
              </vs-tab>
              <vs-tab
                :label="$t(resources.Tracking.i18n) || resources.Tracking.name"
              >
                <tracking
                  :bookingId="bookingId"
                  :productId="`${detail.productId}`"
                  :bookingDetailId="`${detail.bookingDetailId}`"
                ></tracking>
              </vs-tab>
            </vs-tabs>
          </vx-card>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Payment.i18n) || resources.Payment.name"
          icon-pack="feather"
          icon="icon-credit-card"
        >
          <payment
            v-if="bookingDetail.length > 0"
            :bookingId="bookingId"
            :countryOperationCode="countryOperation"
            :stageId="stageId"
            :productId="bookingDetail.find(x => x.supplierId != 0).productId"
            :supplierId="bookingDetail.find(x => x.supplierId != 0).supplierId"
            :currencyCode="`${bookingDetail[0].currencyCode}`"
            :contactId="bookingDetail[0].contactId"
            :bookingList="detailsList"
          ></payment>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Comments.i18n) || t(resources.Comments.name)"
          icon-pack="feather"
          icon="icon-message-circle"
        >
          <comments
            :bookingId="`${bookingId}`"
            :currentStage="stageId"
            :purchaseType="1"
          ></comments>
        </vs-tab>
        <vs-tab
          :label="$t(resources.Documents.i18n) || t(resources.Documents.name)"
          icon-pack="feather"
          icon="icon-file"
          @click="getDocuments"
        >
          <div class="vx-row">
            <div class="vx-col w-full mb-2">
              <vx-card>
                <ul class="vx-timeline">
                  <li v-for="item in documents" :key="item.id" class="mb-2">
                    <div class="timeline-icon" :class="`bg-${item.color}`">
                      <feather-icon
                        icon="FileIcon"
                        svgClasses="text-white stroke-current w-5 h-5"
                      />
                    </div>
                    <small class="activity-e-time">{{ item.time }}</small>
                    <p class="font-semibold">
                      {{
                        $t(resources.CreatedBy.i18n) ||
                          resources.CreatedBy.name
                      }}: {{ item.user }}
                    </p>
                    <div class="timeline-info mt-1">
                      <p class="font-semibold mb-2">
                        {{
                          $t(resources.Subject.i18n) || resources.Subject.name
                        }}: {{ item.title }}
                      </p>
                      <a :href="item.url">{{
                        $t(resources.OpenDocument.i18n) ||
                          resources.OpenDocument.name
                      }}</a>
                    </div>
                  </li>
                </ul>
              </vx-card>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>

    <vs-row vs-type="flex" vs-justify="flex-end">
      <vs-button
        color="rgb(174,174,174)"
        type="filled"
        @click="$router.push('/bookings/index')"
        class="mt-2"
        >{{ $t(resources.Return.i18n) || resources.Return.name }}</vs-button
      >
    </vs-row>

    <vs-popup
      :title="$t(resources.Create.i18n) || resources.Create.name"
      :active.sync="popupAddDetail"
    >
      <div class="vx-row mb-base">
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
          }}</label>
          <v-select
            v-model="createDetail.bookingDetailId"
            :clearable="false"
            :options="bookingDetail"
            :reduce="data => data.bookingDetailId"
            label="bookingReference"
          >
          </v-select>
        </div>
        <div class="vx-col md:w-1/2 mb-2 w-full">
          <label class="vs-input--label">{{
            $t(resources.Supplier.i18n) || resources.Supplier.name
          }}</label>
          <v-select
            v-model="createDetail.supplierId"
            :clearable="true"
            :options="supplierOption"
            :placeholder="$t(resources.Select.i18n) || resources.Select.name"
            :reduce="item => item.id"
            label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.Currency.i18n) || resources.Currency.name
          }}</label>
          <v-select
            v-model="createDetail.currencyId"
            :clearable="false"
            :options="currencyList"
            :reduce="data => data.id"
            label="name"
          >
          </v-select>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.ProductType.i18n) || resources.ProductType.name
          }}</label>
          <v-select
            v-model="createDetail.productId"
            :clearable="true"
            :options="productType"
            :reduce="product => product.id"
            label="name"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              :label="
                $t(resources.BookingCode.i18n) || resources.BookingCode.name
              "
              v-model="createDetail.bookingReference"
              @blur="
                createDetail.bookingReference = createDetail.bookingReference.replace(
                  /\s+/g,
                  ''
                )
              "
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="$t(resources.NetValue.i18n) || resources.NetValue.name"
              v-model="createDetail.value"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-dollar-sign"
              type="number"
              :label="
                $t(resources.TaxesValue.i18n) || resources.TaxesValue.name
              "
              v-model="createDetail.tax"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="$t(resources.FeeValue.i18n) || resources.FeeValue.name"
              v-model="createDetail.fee"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              :label="
                $t(resources.AgentCommissionValue.i18n) ||
                  resources.AgentCommissionValue.name
              "
              v-model="createDetail.agentCommission"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              type="number"
              icon="icon-dollar-sign"
              :label="
                $t(resources.MerchantValue.i18n) || resources.MerchantValue.name
              "
              v-model="createDetail.merchant"
              v-on:change="sumValues(0, true)"
            />
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-dollar-sign"
              icon-no-border
              type="number"
              readonly
              :label="
                $t(resources.BookingValue.i18n) || resources.BookingValue.name
              "
              v-model="createDetail.total"
            />
          </div>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-dollar-sign"
              type="number"
              :label="
                $t(resources.FeeCustomized.i18n) || resources.FeeCustomized.name
              "
              v-model="createDetail.feeCustomized"
            />
          </div>
        </div>
        <!-- <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-dollar-sign"
              type="number"
              readonly
              :label="
                $t(resources.TotalValue.i18n) || resources.TotalValue.name
              "
              :value="
                (createDetail.total + createDetail.feeCustomized).toFixed(2)
              "
            />
          </div>
        </div> -->
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              class="w-full"
              icon-pack="feather"
              icon="icon-user"
              :label="$t(resources.CreatedBy.i18n) || resources.CreatedBy.name"
              v-model="createDetail.createdBy"
              readonly
            />
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <ul class="centerx" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="createDetail.copyItinerary"
                >Copiar itinerarios</vs-checkbox
              >
            </li>
          </ul>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <ul class="centerx" style="margin-top: 20px">
            <li>
              <vs-checkbox v-model="createDetail.copyPassenger"
                >Copiar Pasajeros</vs-checkbox
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="vx-col mb-2 mt-5">
        <vs-button color="primary" type="filled" @click="saveDetail()">
          {{ $t(resources.Save.i18n) || resources.Save.name }}
        </vs-button>
      </div>
    </vs-popup>

    <vs-popup
      :title="$t(resources.Passengers.i18n) || resources.Passengers.name"
      :active.sync="popupActivePassenger"
    >
      <div class="vx-row mb-4">
        <div class="vx-col md:w-1/2 w-full">
          <vs-select
            :label="
              $t(resources.PassengerType.i18n) || resources.PassengerType.name
            "
            v-model="passenger.passengerTypeId"
            class="w-full"
          >
            <vs-select-item
              readonly
              v-for="(item, index) in passengerType"
              :key="index"
              :value="item.value"
              :text="item.text"
            />
          </vs-select>
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <vs-select
            :label="
              $t(resources.DocumentType.i18n) || resources.DocumentType.name
            "
            v-model="passenger.documentType"
            class="w-full"
          >
            <vs-select-item
              readonly
              :key="index"
              :value="item.id"
              :text="item.name"
              v-for="(item, index) in identifiacionTypeList"
            />
          </vs-select>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              readonly
              class="w-full"
              icon-pack="feather"
              icon="icon-hash"
              icon-no-border
              :label="
                $t(resources.DocumentNumber.i18n) ||
                  resources.DocumentNumber.name
              "
              v-model="passenger.documentNumber"
            />
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-hash"
            :label="$t(resources.BirthDate.i18n) || resources.BirthDate.name"
            v-model="passenger.birthDate"
          />
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-user"
            :label="$t(resources.Name.i18n) || resources.Name.name"
            v-model="passenger.firstName"
          />
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-user"
            :label="$t(resources.LastName.i18n) || resources.LastName.name"
            v-model="passenger.lastName"
          />
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-select
            :label="$t(resources.Gender.i18n) || resources.Gender.name"
            v-model="passenger.genderId"
            class="w-full"
          >
            <vs-select-item
              readonly
              v-for="(item, index) in genderType"
              :key="index"
              :value="item.value"
              :text="item.text"
            />
          </vs-select>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <label class="vs-input--label">{{
            $t(resources.Country.i18n) || resources.Country.name
          }}</label>
          <v-select
            readonly
            v-model="passenger.nationalityId"
            :clearable="false"
            :options="countryList"
            :reduce="country => country.id"
            label="name"
          />
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-hash"
            :label="
              $t(resources.ExpirationDatePassport.i18n) ||
                resources.ExpirationDatePassport.name
            "
            v-model="passenger.passportExpiration"
          />
        </div>
        <div class="vx-col md:w-1/2 w-full">
          <div class="vx-col w-full">
            <vs-input
              readonly
              class="w-full"
              icon-pack="feather"
              icon-no-border
              icon="icon-phone"
              type="number"
              :label="$t(resources.Phone.i18n) || resources.Phone.name"
              v-model="passenger.telephone"
            />
          </div>
        </div>

        <div class="vx-col md:w-1/2 w-full">
          <vs-input
            readonly
            class="w-full"
            icon-pack="feather"
            icon-no-border
            icon="icon-hash"
            type="number"
            :label="
              $t(resources.TicketNumber.i18n) || resources.TicketNumber.name
            "
            v-model="passenger.ticketNumber"
          />
        </div>
      </div>
      <vs-divider class="mb-2">
        <h4 class="font-semibold mb-base">
          {{
            $t(resources.Specifications.i18n) || resources.Specifications.name
          }}
        </h4>
      </vs-divider>
      <div class="vx-row">
        <div class="vx-col w-full">
          <vs-table :data="passenger.passengerSpecification">
            <template slot="thead">
              <vs-th>{{
                $t(resources.SpecificationName.i18n) ||
                  resources.SpecificationName.name
              }}</vs-th>
            </template>

            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].specificationValue">{{
                  data[indextr].specificationValue
                }}</vs-td>
              </vs-tr>
            </template>
          </vs-table>
        </div>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "axios";
import resources from "@/i18n/resources.js";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import VxTimeline from "@/components/timeline/VxTimeline";
import tracking from "./tracking.vue";
import itinerary from "./itinerary.vue";
import payment from "./payment.vue";
import utilities from "@/assets/resources/utilities.json";
import comments from "./comments.vue";

export default {
  name: "Autocomplete",
  components: {
    Datepicker,
    vSelect,
    VxTimeline,
    tracking,
    itinerary,
    payment,
    comments
  },
  data() {
    return {
      resources: resources,
      isNavOpen: false,
      document: "",
      name: "",
      lastName: "",
      utilities: utilities,
      timelineDocuments: [],
      trace: [],
      language: this.$i18n.locale,
      languages: lang,
      expirateDate: null,
      bookingId: 0,
      accountNumber: 0,
      contactName: "",
      email: "",
      countryOperation: "",
      bookingCode: "",
      source: "",
      stageId: "",
      netValue: 0,
      taxValue: 0,
      feeValue: 0,
      feeCustomized: 0,
      merchantValue: 0,
      discountValue: 0,
      totalValue: 0,
      otherValue: 0,
      bookingDetail: [],
      passengerDetail: [],
      documents: [],
      identifiacionTypeList: [],
      countryList: [],
      passenger: {
        id: 0,
        bookingId: 0,
        bookingDetailId: 0,
        ticketNumber: "",
        email: "",
        passengerTypeId: 0,
        passengerTypeName: "",
        phoneType: 0,
        nationality: "",
        telephone: "",
        firstName: "",
        lastName: "",
        documentType: 0,
        documentNumber: "",
        title: "",
        areaCityCode: "",
        fare: 0.0,
        fee: 0.0,
        tax: 0.0,
        birthDate: "",
        passportExpiration: "",
        genderId: 0,
        nationalityId: 0,
        currencyCode: "",
        passengerSpecification: [
          {
            specificationId: 0,
            specificationName: "",
            specificationValue: ""
          }
        ]
      },
      popupActivePassenger: false,
      passengerType: [
        { text: "Adulto", value: 1 },
        { text: "Niño", value: 7 },
        { text: "Niño Acompañado", value: 3 }
      ],
      genderType: [
        { text: "Seleccione", value: 0 },
        { text: "Femenino", value: 1 },
        { text: "Masculino", value: 2 },
        { text: "Otro", value: 3 }
      ],
      select2: 1,
      options2: [
        { text: "Cedula", value: 1 },
        { text: "ID", value: 7 },
        { text: "Pasaporte", value: 3 }
      ],
      selected: null,
      model: null,
      mode: "input",
      loading: false,
      log: [],
      remarkDto: {},
      detailsList: [],
      popupAddDetail: false,
      currencyList: [],
      productType: [],
      supplierOption: [],
      createDetail: {
        bookingId: 0,
        bookingDetailId: 0,
        bookingReference: "",
        productId: 0,
        currencyId: 0,
        bookingSourceId: 21,
        fee: 0,
        tax: 0,
        merchant: 0,
        feeCustomized: 0,
        total: 0,
        value: 0,
        discount: 0,
        discountIn: 0,
        createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
        airLineCommissionValue: 0,
        rateValue: 0,
        agentCommission: 0,
        language: this.$i18n.locale.toUpperCase(),
        supplierId: 0,
        isChange: false,
        copyItinerary: true,
        copyPassenger: true
      },
      allowManage: false
    };
  },
  methods: {
    async getDetail() {
      this.netValue = 0;
      this.taxValue = 0;
      this.feeValue = 0;
      this.feeCustomized = 0;
      this.merchantValue = 0;
      this.discountValue = 0;
      this.totalValue = 0;
      this.otherValue = 0;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Booking/ListByFilters`,
        data: {
          searchKeys: {
            bookingid: this.bookingId
          },
          page: 1,
          rows: 10,
          orderBy: "FechaCreacion-DESC",
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.contactName = `${result.data.data[0].contactName} ${result.data.data[0].contactLastName}`;
            this.email = `${result.data.data[0].contactEmail}`;
            this.countryOperation = `${result.data.data[0].countryCode}`;
            this.bookingCode = result.data.data[0].bookingCode;
            this.stageId = result.data.data[0].stageId;
            //this.source = result.data.data[0].bookingSourceName;
            this.bookingDetail = result.data.data;
            result.data.data
              .filter(x => x.stageId != 8 && x.stageId != 7)
              .forEach(item => {
                this.netValue += item.value;
                this.taxValue += item.tax;
                this.feeValue +=
                  item.fee + item.merchant + item.agentCommissionValue;
                this.feeCustomized += item.feeCustomized;
                this.totalValue += item.total;

                this.detailsList.push({
                  id: item.bookingDetailId,
                  value: item.bookingReference
                });
              });
            this.netValue = parseFloat(this.netValue).toFixed(2);
            // this.taxValue += item.tax;
            // this.feeValue += (item.fee + item.merchant);
            // this.feeCustomized += item.feeCustomized;
            this.totalValue = parseFloat(this.totalValue).toFixed(2);
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getPassenger() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Passenger/${this.$i18n.locale.toUpperCase()}/${this.bookingId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) this.passengerDetail = result.data.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getIdentifiacionType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.identifiacionTypeList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async postDetail(item) {
      item.language = this.$i18n.locale;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BookingDetail/Update`,
        data: item,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("MsgSaved"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.getDetail();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCurrency() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Currency/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.currencyList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text:
              this.$i18n.t("MsgError") + "<br/>" + error.response.data.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getSupplier() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Supplier`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.length == 0) {
            this.supplierOption = [];
          } else {
            this.supplierOption = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getProductType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Product/ByType/${this.$i18n.locale.toUpperCase()}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.productType = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async saveDetail() {
      if (this.createDetail.feeCustomized > (this.createDetail.total * 0.15)){
          this.$vs.notify({
            title: this.$i18n.t("Alert"),
            text: `El fee personalizado no puede ser mayor a $${(this.createDetail.total * 0.15).toFixed(2).toLocaleString('es-MX')}, equivalente al 15% del valor de la reserva.`,
            color: "warning",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          return;
      }

      this.$vs.loading();
      this.createDetail.bookingId = this.bookingId;

      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BookingDetail/Create`,
        data: this.createDetail,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved"),
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupAddDetail = false;
            this.createDetail = {
              bookingId: this.bookingId,
              bookingDetailId: 0,
              bookingReference: "",
              productId: 0,
              currencyId: 0,
              bookingSourceId: 21,
              fee: 0,
              tax: 0,
              merchant: 0,
              feeCustomized: 0,
              total: 0,
              value: 0,
              discount: 0,
              discountIn: 0,
              createdBy: JSON.parse(localStorage.getItem("userInfo")).email,
              airLineCommissionValue: 0,
              rateValue: 0,
              agentCommission: 0,
              supplierId: 0,
              language: this.$i18n.locale.toUpperCase()
            };
            await this.getDetail();
            await this.getPassenger();
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
      this.$vs.loading.close();
    },

    showPopUpPassenger(item, isEdit) {
      if (isEdit) this.passenger = item;
      else {
        this.passenger = null;
      }

      this.popupActivePassenger = true;
    },

    sumValues(index, isCreate) {
      if (isCreate) {
        let _total =
          parseFloat(this.createDetail.value) +
          parseFloat(this.createDetail.tax) +
          parseFloat(this.createDetail.fee) +
          parseFloat(this.createDetail.agentCommission);

        if(this.createDetail.supplierId == 1 && this.countryOperation != "ARG")
          this.createDetail.merchant = 0;
        else if(this.createDetail.supplierId == 2)  
          this.createDetail.merchant = parseFloat(_total * 0.07).toFixed(2);
        else if(this.createDetail.productId == 6)  
          this.createDetail.merchant = 0;
        else
          this.createDetail.merchant = parseFloat(_total * 0.04).toFixed(2);

        this.createDetail.total =
          parseFloat(_total) + parseFloat(this.createDetail.merchant);
      } else {
        parseFloat(
          (this.bookingDetail[index].total =
            parseFloat(this.bookingDetail[index].value) +
            parseFloat(this.bookingDetail[index].tax) +
            parseFloat(this.bookingDetail[index].fee) +
            parseFloat(this.bookingDetail[index].merchant) +
            parseFloat(this.bookingDetail[index].agentCommissionValue))
        ).toFixed(2);

        parseFloat(
          (this.bookingDetail[index].totalComplete =
            parseFloat(this.bookingDetail[index].total) +
            parseFloat(this.bookingDetail[index].feeCustomized) +
            parseFloat(this.bookingDetail[index].discount))
        ).toFixed(2);
      }
    },

    filterPassenger(item) {
      return this.passengerDetail.filter(
        passenger => passenger.bookingDetailId == item
      );
    },
    simpleSuggestionList() {
      return ["Vue.js", "React.js", "Angular.js"];
    },
    onSuggestSelect(suggest) {
      this.selected = suggest;
    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const { suggestion, query } = scope;
      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;
      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [""];
      return result.replace(
        new RegExp("(.*?)(" + texts.join("|") + ")(.*?)", "gi"),
        "$1<b>$2</b>$3"
      );
    },

    async getDocuments() {
      this.documents = [];
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}Remarks/Search`,
        data: {
          searchKeys: {
            identifier: this.bookingId,
            entityid: this.utilities.entity.booking,
            hasattachment: true,
            ispublish: true
          },
          page: 1,
          rows: 20,
          language: this.$i18n.locale.toUpperCase()
        },
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT")
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.documents = [];
          } else {
            let response = result.data.data;
            for (var i = 0; i < response.length; i++) {
              this.documents.push({
                id: i,
                color: "success",
                title: response[i].subject,
                time: response[i].creationDate,
                user: response[i].createdBy,
                url: response[i].pathUrl
              });
            }
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  async mounted() {
    this.$vs.loading();

    this.bookingId = this.$route.params.bookingId;
    await this.getDetail();
    await this.getPassenger();
    await this.getIdentifiacionType();
    await this.getCountry();
    await this.getSupplier();
    await this.getCurrency();
    await this.getProductType();

    this.$vs.loading.close();
  }
};
</script>
<style lang="css">
.overFlow {
  overflow-x: hidden;
}
</style>
