var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: { "max-items": "6", pagination: "", data: _vm.tracking },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].previousStageName } },
                        [_vm._v(_vm._s(data[indextr].previousStageName))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].newStageName } },
                        [_vm._v(_vm._s(data[indextr].newStageName))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].createdDate } },
                        [_vm._v(_vm._s(data[indextr].createdDate))]
                      )
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.PreviousStage.i18n) ||
                      _vm.t(_vm.resources.PreviousStage.name)
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.CurrentStage.i18n) ||
                      _vm.t(_vm.resources.CurrentStage.name)
                  )
                )
              ]),
              _c("vs-th", { attrs: { align: "center" } }, [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Date.i18n) ||
                      _vm.t(_vm.resources.Date.name)
                  )
                )
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }