<template>
  <div>
    <vx-card
      class="mb-4"
      :title="$t(resources.List.i18n) || resources.List.name"
    >
      <vs-row
        vs-type="flex"
        vs-justify="center"
        class="mb-base mt-2"
        v-if="
          countryOperationCode != 'MEX' &&
            countryOperationCode != 'DOM' &&
            (stageId == database.stage.Commercial ||
              stageId == database.stage.Risk ||
              stageId == database.stage.Issue ||
              stageId == database.stage.WithoutBooking ||
              stageId == database.stage.Payment ||
              stageId == database.stage.Antifraud)
        "
      >
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
          <vs-button
            radius
            color="danger"
            type="gradient"
            icon-pack="feather"
            icon="icon-link"
            size="large"
            @click="popupGenerate = true"
          ></vs-button>
        </vs-col>
      </vs-row>
      <vs-table :data="payments" class="mb-base">
        <template slot="thead">
          <!-- <vs-th>{{$t(resources.Name.i18n) || resources.Name.name}}</vs-th> -->
          <vs-th>{{
            $t(resources.CurrencyCode.i18n) || resources.CurrencyCode.name
          }}</vs-th>
          <vs-th>{{
            $t(resources.Merchant.i18n) || resources.Merchant.name
          }}</vs-th>
          <vs-th>{{ $t(resources.Value.i18n) || resources.Value.name }}</vs-th>
          <vs-th>{{
            $t(resources.TransactionNumber.i18n) ||
              resources.TransactionNumber.name
          }}</vs-th>
          <vs-th>{{ $t(resources.Type.i18n) || resources.Type.name }}</vs-th>
          <vs-th>{{
            $t(resources.CreditCardNumber.i18n) ||
              resources.CreditCardNumber.name
          }}</vs-th>
          <vs-th>{{ $t(resources.Paid.i18n) || resources.Paid.name }}</vs-th>
          <vs-th>{{ $t(resources.Date.i18n) || resources.Date.name }}</vs-th>
          <!-- <vs-th>{{$t(resources.Options.i18n) || resources.Options.name}}</vs-th> -->
        </template>

        <template slot-scope="{ data }">
          <vs-tr :key="indextr" v-for="(tr, indextr) in data">
            <!-- <vs-td :data="data[indextr].firstName">{{data[indextr].firstName}}</vs-td> -->
            <vs-td :data="data[indextr].currencyCode">{{
              data[indextr].currencyCode
            }}</vs-td>
            <vs-td :data="data[indextr].merchantName">{{
              data[indextr].merchantName
            }}</vs-td>
            <vs-td :data="data[indextr].value">{{ data[indextr].value }}</vs-td>
            <vs-td :data="data[indextr].transactionNumber">{{
              data[indextr].transactionNumber
            }}</vs-td>
            <vs-td :data="data[indextr].cardType">{{
              data[indextr].cardType
            }}</vs-td>
            <vs-td :data="data[indextr].cardNumber">{{
              data[indextr].cardNumber
            }}</vs-td>
            <vs-td :data="data[indextr].isPayed" v-if="data[indextr].isPayed">{{
              $t(resources.Yes.i18n) || resources.Yes.name
            }}</vs-td>
            <vs-td :data="data[indextr].isPayed" v-else>{{
              $t(resources.No.i18n) || resources.No.name
            }}</vs-td>
            <vs-td :data="data[indextr].createdDate"
              >{{ data[indextr].createdDate }}
              {{ data[indextr].createdTime }}</vs-td
            >
            <!-- <td></td> -->
          </vs-tr>
        </template>
      </vs-table>
    </vx-card>
    <vx-card
      :title="$t(resources.BillingData.i18n) || resources.BillingData.name"
    >
      <div>
        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                :label="$t(resources.Names.i18n) || resources.Names.name"
                v-model="billingData.name"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-user"
                icon-no-border
                :label="$t(resources.LastName.i18n) || resources.LastName.name"
                v-model="billingData.lastName"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-mail"
                icon-no-border
                type="email"
                :label="$t(resources.Email.i18n) || resources.Email.name"
                v-model="billingData.email"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-phone"
                icon-no-border
                :label="$t(resources.Phone.i18n) || resources.Phone.name"
                v-model="billingData.phone"
              />
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full">
            <label class="vs-input--label">{{
              $t(resources.Country.i18n) || resources.Country.name
            }}</label>
            <v-select
              v-model="billingData.countryId"
              :clearable="false"
              :options="countryList"
              :reduce="country => country.id"
              label="name"
              v-on:input="getStateByCountry(`${billingData.countryId}`)"
              :hint="`${billingData.countryId}`"
            />
          </div>

          <div class="vx-col md:w-1/4 w-full mb-6">
            <label class="vs-input--label">{{
              $t(resources.State.i18n) || resources.State.name
            }}</label>
            <v-select
              v-model="billingData.stateId"
              :clearable="false"
              :options="stateCountry"
              :reduce="state => state.id"
              label="name"
              v-on:input="getCityByState(`${billingData.stateId}`)"
              :hint="`${billingData.stateId}`"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-6">
            <label class="vs-input--label">{{
              $t(resources.Destination.i18n) || resources.Destination.name
            }}</label>
            <v-select
              v-model="billingData.cityId"
              :clearable="false"
              :options="destinationList"
              :reduce="city => city.id"
              label="name"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-map-pin"
                icon-no-border
                :label="$t(resources.Address.i18n) || resources.Address.name"
                v-model="billingData.address"
              />
            </div>
          </div>
        </div>

        <div class="vx-row">
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="$t(resources.ZipCode.i18n) || resources.ZipCode.name"
                v-model="billingData.zipCode"
              />
            </div>
          </div>
          <div class="vx-col md:w-1/4 w-full mb-6">
            <label class="vs-input--label">{{
              $t(resources.DocumentType.i18n) || resources.DocumentType.name
            }}</label>
            <v-select
              v-model="billingData.documentTypeId"
              :clearable="false"
              :options="identifiacionTypeList"
              :reduce="item => item.id"
              label="name"
            />
          </div>
          <div class="vx-col md:w-1/4 w-full mb-2">
            <div class="vx-col w-full">
              <vs-input
                class="w-full"
                icon-pack="feather"
                icon="icon-hash"
                icon-no-border
                :label="
                  $t(resources.DocumentNumber.i18n) ||
                    resources.DocumentNumber.name
                "
                v-model="billingData.documentNumber"
              />
            </div>
          </div>
        </div>

        <vs-row
          vs-type="flex"
          vs-justify="flex-end"
          v-if="
            stageId == database.stage.Commercial ||
              stageId == database.stage.Risk ||
              stageId == database.stage.Issue ||
              stageId == database.stage.WithoutBooking ||
              stageId == database.stage.Payment ||
              stageId == database.stage.Antifraud
          "
        >
          <vs-button
            v-if="isUpdateBillingData"
            class="mr-0 mb-2"
            @click="createBillingData(billingData)"
            >{{ $t(resources.Save.i18n) || resources.Save.name }}</vs-button
          >
          <vs-button
            v-else
            class="mr-0 mb-2"
            @click="updateBillingData(billingData)"
            >{{ $t(resources.Save.i18n) || resources.Save.name }}</vs-button
          >
        </vs-row>
      </div>
    </vx-card>

    <vs-popup
      classContent="popup overFlow"
      :title="$t(resources.Generate.i18n) || resources.Generate.name"
      :active.sync="popupGenerate"
    >
      <vs-input
        type="email"
        class="w-full"
        icon-pack="feather"
        icon="icon-mail"
        placeholder
        :label="$t(resources.Email.i18n) || resources.Email.name"
        :maxlength="100"
        v-model="creditCardEmail"
      />

      <div class="vx-col w-full">
        <label class="vs-input--label">{{
          $t(resources.ReferenceCode.i18n) || resources.ReferenceCode.name
        }}</label>
        <v-select
          v-model="identifier"
          :clearable="false"
          :options="bookingList"
          :reduce="data => data.id"
          label="value"
        >
        </v-select>
      </div>

      <div class="vx-col mb-2 mt-5" style="text-align: right">
        <vs-button color="primary" type="filled" @click="sendMail()">
          {{ $t(resources.Send.i18n) || resources.Send.name }}
        </vs-button>
      </div>

      <div class="vx-row" v-if="paymentLink !== null && paymentLink !== ''">
        <div class="vx-col w-full md:w-3/4 mb-2 mt-base">
          <vs-input
            v-model="paymentLink"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-4 mt-base">
          <vs-button
            v-clipboard:copy="paymentLink"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";
import vSelect from "vue-select";
import axios from "axios";
import database from "@/assets/resources/enums/database.json";

export default {
  components: {
    vSelect,
    Datepicker
  },
  name: "payment",
  props: {
    bookingId: String,
    countryOperationCode: String,
    productId: Number,
    stageId: Number,
    supplierId: Number,
    currencyCode: String,
    contactId: Number,
    bookingList: Array
  },
  data() {
    return {
      database: database,
      tracking: [],
      resources: resources,
      popupGenerate: false,
      utilities: utilities,
      language: this.$i18n.locale,
      languages: lang,
      popupPaymentActive: false,
      popupRecobroActive: false,
      popupTarjetaCreditoActive: false,
      checkGeneratePayments: false,
      billingData: {},
      merchant: "",
      total: "",
      creditCardEmail: "",
      payments: [],
      cardsOption: [],
      cards: "",
      applicationMerchantType: [],
      paymentCreateDto: {},
      merchantType: [],
      creditCardType: [],
      productType: [],
      supplierType: [],
      countryOperationType: [],
      currencyType: [],
      bankType: [],
      bankTypeId: 0,
      accountNumberType: [],
      stateCountry: [],
      merchantOption: [],
      destinationList: [],
      identifiacionTypeList: [],
      isWayToPay: false,
      countryList: [],
      isUpdateBillingData: false,
      emailSession: JSON.parse(localStorage.getItem("userInfo")).email,
      identifier: "",
      wayToPayOption: [],
      paymentLink: ""
    };
  },
  methods: {
    async getPayment() {
      await axios({
        method: "GET",
        url: `${process.env.VUE_APP_APIVT}Payment/ByBooking/${this.$i18n.locale}/${this.bookingId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.payments = result.data.data;
            this.cardsOption = result.data.data.map(x => ({
              value: x.id,
              text: x.cardNumber
            }));
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getBillingData() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }BillingData/${this.$i18n.locale.toUpperCase()}/${this.bookingId}/1`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        async result => {
          if (result.data.success) {
            this.billingData = result.data.data;
            this.isUpdateBillingData = false;
            await this.getStateByCountry(`${this.billingData.countryId}`);
            await this.getCityByState(`${this.billingData.stateId}`);
          } else {
            this.isUpdateBillingData = true;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async sendMail() {
      if (this.wayToPayOption.find(x => x.code == "TC") == undefined) {
        this.$vs.notify({
          color: "warning",
          title: this.$i18n.t("Alert"),
          position: "top-right",
          icon: "icon-x-circle",
          text:
            "Debe configurar las formas de pago para enviar el LINK DE PAGOS."
        });
      } else {
        this.$vs.loading();
        await axios({
          method: "POST",
          url: `${process.env.VUE_APP_APIVT}Emails/PaymentLink`,
          data: {
            bookingId: this.bookingId,
            language: "ES",
            email: this.creditCardEmail,
            isCreditCard: 0,
            createdBy: this.emailSession
          },
          headers: {
            "content-type": "application/json",
            Authorization: `bearer ${localStorage.getItem("tokenVT")}`
          }
        }).then(
          result => {
            if (!result.status) {
              this.$vs.notify({
                title: this.$i18n.t("Error"),
                text: this.$i18n.t("MsgEmailNotSent"),
                color: "danger",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.popupGenerate = false;
              this.$vs.loading.close();
            } else {
              this.$vs.notify({
                title: this.$i18n.t("Success"),
                text: this.$i18n.t("MsgEmailSent"),
                color: "success",
                iconPack: "feather",
                position: "top-right",
                icon: "icon-x-circle"
              });
              this.paymentLink = `https://foca.visiontravel.net/payment/online/ES/0/${this.bookingId}`;
              this.$vs.loading.close();
            }
          },
          error => {
            this.$vs.notify({
              title: this.$i18n.t("Error"),
              text: this.$i18n.t("MsgEmailNotSent") + "<br />" + error.message,
              color: "danger",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.popupGenerate = false;
            this.$vs.loading.close();
          }
        );
      }
    },

    async updateBillingData(data) {
      data.english = this.$i18n.locale.toUpperCase();
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BillingData/Update`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data.success) {
            this.billingData = result.data.data;
            this.getStateByCountry(`${this.billingData.countryId}`);
            this.getCityByState(`${this.billingData.stateId}`);
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async createBillingData(data) {
      let contactInfo = JSON.parse(localStorage.getItem("userInfo"));
      data.english = this.$i18n.locale.toUpperCase();
      data.id = this.bookingId;
      data.entity = this.utilities.entity.booking;
      data.createBy = contactInfo.email;
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}BillingData/Billing`,
        data: data,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.getBillingData();
            this.$vs.notify({
              title: this.$i18n.t("Success"),
              text: this.$i18n.t("MsgSaved") + "<br/>" + result.data.message,
              color: "success",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCountry() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Country/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) {
            this.countryList = result.data;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getStateByCountry(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }State/GetByCountry/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.stateCountry = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getCityByState(item) {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }City/GetByState/${this.$i18n.locale.toUpperCase()}/${item}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.destinationList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getIdentifiacionType() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }IdentificationType/${this.$i18n.locale.toUpperCase()}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (result.data) this.identifiacionTypeList = result.data;
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    },

    async getWayToPay() {
      await axios({
        method: "POST",
        url: `${process.env.VUE_APP_APIVT}WayToPay/GetAvailable`,
        data: {
          applicationId: this.database.application.FOC,
          countryCode: this.countryOperationCode,
          productId: this.productId,
          supplierId: this.supplierId,
          language: this.$i18n.locale.toUpperCase(),
          currencyCode: this.currencyCode,
          contactId: this.contactId
        },
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data) {
            this.wayToPayOption = [];
          } else {
            this.wayToPayOption = result.data.wayToPay;
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
          this.merchantOption = [];
        }
      );
    },

        onCopy() {
      this.$vs.notify({
        title: this.$i18n.t("Success"),
        text: this.$i18n.t("MsgCopy"),
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle",
      });
    },

    onError() {
      this.$vs.notify({
        title: this.$i18n.t("Error"),
        text: this.$i18n.t("MsgError"),
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle",
      });
    },
  },
  async created() {
    this.$vs.loading();
    await this.getCountry();
    await this.getIdentifiacionType();
    await this.getBillingData();
    await this.getWayToPay();
    await this.getPayment();
    this.$vs.loading.close();
  }
};
</script>
