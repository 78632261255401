<template>
  <div>
    <vs-table max-items="6" pagination :data="tracking">
      <template slot="thead">
        <vs-th>{{
          $t(resources.PreviousStage.i18n) || t(resources.PreviousStage.name)
        }}</vs-th>
        <vs-th>{{
          $t(resources.CurrentStage.i18n) || t(resources.CurrentStage.name)
        }}</vs-th>
        <vs-th align="center">{{
          $t(resources.Date.i18n) || t(resources.Date.name)
        }}</vs-th>
      </template>
      <template slot-scope="{ data }">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td :data="data[indextr].previousStageName">{{
            data[indextr].previousStageName
          }}</vs-td>
          <vs-td :data="data[indextr].newStageName">{{
            data[indextr].newStageName
          }}</vs-td>
          <vs-td :data="data[indextr].createdDate">{{
            data[indextr].createdDate
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import utilities from "@/assets/resources/utilities.json";
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";

export default {
  components: {
    vSelect,
  },
  name: "tracking",
  props: {
    bookingId: String,
    productId: String,
    bookingDetailId: String,
  },
  data() {
    return {
      tracking: [],
      resources: resources,

      utilities: utilities,
      stage: 0,
      remark: "",
      stageOption: [
        { text: "Commercial", value: "1", i18n: "Commercial" },
        { text: "RiskAnalysis", value: "2", i18n: "RiskAnalysis" },
        { text: "PaymentStage", value: "3", i18n: "PaymentStage" },
        { text: "Issue", value: "4", i18n: "Issue" },
        { text: "Billing", value: "5", i18n: "Billing" },
        { text: "Commission", value: "6", i18n: "Commission" },
        { text: "Returns", value: "7", i18n: "Returns" },
        { text: "Cancellations", value: "8", i18n: "Cancellations" },
        { text: "Changes", value: "9", i18n: "Changes" },
        { text: "ChargeSuppliers", value: "10", i18n: "ChargeSuppliers" },
        { text: "Recovery", value: "11", i18n: "Recovery" },
        { text: "Fraud", value: "12", i18n: "Fraud" },
      ],
    };
  },
  methods: {
    async getTracking() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Tracking/${this.$i18n.locale.toUpperCase()}/${this.bookingDetailId}/5`,
        headers: {
          "content-type": "application/json",
          Authorization: "bearer " + localStorage.getItem("tokenVT"),
        },
      }).then(
        (result) => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle",
            });
            this.tracking = [];
          } else {
            this.tracking = result.data.data;
          }
        },
        (error) => {
          this.$vs.notify({
            title: this.$i18n.t("Error"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle",
          });
        }
      );
    },
  },
  mounted() {
    this.getTracking();
  },
};
</script>