var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-table",
        {
          attrs: { data: _vm.itineraryDetail },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function(ref) {
                var data = ref.data
                return _vm._l(data, function(tr, indextr) {
                  return _c(
                    "vs-tr",
                    { key: indextr },
                    [
                      data[indextr].productId == 2
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].origin } },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(data[indextr].originCode) +
                                  ") " +
                                  _vm._s(data[indextr].origin)
                              )
                            ]
                          )
                        : _vm._e(),
                      data[indextr].productId == 2
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].destination } },
                            [
                              _vm._v(
                                "(" +
                                  _vm._s(data[indextr].destinationCode) +
                                  ") " +
                                  _vm._s(data[indextr].destination)
                              )
                            ]
                          )
                        : _vm._e(),
                      data[indextr].productId != 2
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].origin } },
                            [_vm._v(_vm._s(data[indextr].origin))]
                          )
                        : _vm._e(),
                      data[indextr].productId != 2
                        ? _c(
                            "vs-td",
                            { attrs: { data: data[indextr].destination } },
                            [_vm._v(_vm._s(data[indextr].destination))]
                          )
                        : _vm._e(),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].departureDate } },
                        [_vm._v(_vm._s(data[indextr].departureDate))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].arrivalDate } },
                        [_vm._v(_vm._s(data[indextr].arrivalDate))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].supplierName } },
                        [_vm._v(_vm._s(data[indextr].supplierName))]
                      ),
                      _c(
                        "vs-td",
                        { attrs: { data: data[indextr].serviceSupplierName } },
                        [_vm._v(_vm._s(data[indextr].serviceSupplierName))]
                      )
                    ],
                    1
                  )
                })
              }
            }
          ])
        },
        [
          _c(
            "template",
            { slot: "thead" },
            [
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Origin.i18n) ||
                      _vm.resources.Origin.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.Destination.i18n) ||
                      _vm.resources.Destination.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.StartDate.i18n) ||
                      _vm.resources.StartDate.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.EndDate.i18n) ||
                      _vm.resources.EndDate.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.SupplierName.i18n) ||
                      _vm.resources.SupplierName.name
                  )
                )
              ]),
              _c("vs-th", [
                _vm._v(
                  _vm._s(
                    _vm.$t(_vm.resources.LenderServices.i18n) ||
                      _vm.resources.LenderServices.name
                  )
                )
              ])
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }