<template>
  <div>
    <vs-table :data="itineraryDetail">
      <template slot="thead">
        <vs-th>{{$t(resources.Origin.i18n) || resources.Origin.name}}</vs-th>
        <vs-th>{{$t(resources.Destination.i18n) || resources.Destination.name}}</vs-th>
        <vs-th>{{$t(resources.StartDate.i18n) || resources.StartDate.name}}</vs-th>
        <vs-th>{{$t(resources.EndDate.i18n) || resources.EndDate.name}}</vs-th>
        <vs-th>{{$t(resources.SupplierName.i18n) || resources.SupplierName.name}}</vs-th>
        <vs-th>{{
          $t(resources.LenderServices.i18n) || resources.LenderServices.name
        }}</vs-th>
      </template>

      <template slot-scope="{data}">
        <vs-tr :key="indextr" v-for="(tr, indextr) in data">
          <vs-td
            v-if="data[indextr].productId == 2"
            :data="data[indextr].origin"
          >({{data[indextr].originCode}}) {{data[indextr].origin}}</vs-td>
          <vs-td
            v-if="data[indextr].productId == 2"
            :data="data[indextr].destination"
          >({{data[indextr].destinationCode}}) {{data[indextr].destination}}</vs-td>
          <vs-td
            v-if="data[indextr].productId != 2"
            :data="data[indextr].origin"
          >{{data[indextr].origin}}</vs-td>
          <vs-td
            v-if="data[indextr].productId != 2"
            :data="data[indextr].destination"
          >{{data[indextr].destination}}</vs-td>
          <vs-td :data="data[indextr].departureDate">{{data[indextr].departureDate}}</vs-td>

          <vs-td :data="data[indextr].arrivalDate">{{data[indextr].arrivalDate}}</vs-td>
          <vs-td :data="data[indextr].supplierName">{{data[indextr].supplierName}}</vs-td>
          <vs-td :data="data[indextr].serviceSupplierName">{{
            data[indextr].serviceSupplierName
          }}</vs-td>
        </vs-tr>
      </template>
    </vs-table>
  </div>
</template>
<script>
import resources from "@/i18n/resources.js";
import vSelect from "vue-select";
import axios from "axios";
import VueSimpleSuggest from "vue-simple-suggest";
import "vue-simple-suggest/dist/styles.css";
import Prism from "vue-prism-component";
import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

export default {
  components: {
    vSelect,
    VueSimpleSuggest,
    Prism,
    Datepicker
  },
  name: "itinerary",
  props: {
    bookingId: String,
    productId: Number,
    bookingDetailId: Number
  },
  data() {
    return {
      tracking: [],
      resources: resources,

      stage: 0,
      remark: "",
      itineraryDetail: [],
      chosen: "",
      origin: "",
      originOption: [],
      destination: "",
      destinationOption: [],
      gds: "",
      gdsOption: [],
      lender: "",
      lenderOption: [],
      language: this.$i18n.locale,
      languages: lang,
      departureDate: "",
      arrivalDate: "",
      flightClass: "",
      flightNumber: ""
    };
  },
  methods: {
    async getItinerary() {
      await axios({
        method: "GET",
        url: `${
          process.env.VUE_APP_APIVT
        }Itinerary/${this.$i18n.locale.toUpperCase()}/${this.bookingId}`,
        headers: {
          "content-type": "application/json",
          Authorization: `bearer ${localStorage.getItem("tokenVT")}`
        }
      }).then(
        result => {
          if (!result.data.success) {
            this.$vs.notify({
              title: this.$i18n.t("Alert"),
              text: this.$i18n.t("MsgNotFoundInfo"),
              color: "warning",
              iconPack: "feather",
              position: "top-right",
              icon: "icon-x-circle"
            });
            this.itineraryDetail = [];
          } else {
            this.itineraryDetail = result.data.data.filter(
              x => x.bookingDetailId == this.bookingDetailId
            );
          }
        },
        error => {
          this.$vs.notify({
            title: this.$i18n.t("MsgError"),
            text: this.$i18n.t("MsgError") + "<br/>" + error.message,
            color: "danger",
            iconPack: "feather",
            position: "top-right",
            icon: "icon-x-circle"
          });
        }
      );
    }
  },
  mounted() {
    this.getItinerary();
  }
};
</script>